import { Grid, ThemeProvider, Typography } from "@material-ui/core";
import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import theme from "../theme";
import Image from "../components/image";

const BemiddelingEnAdviesPage = () => (
  <React.Fragment>
    <ThemeProvider theme={theme}>
      <Layout>
        <SEO title="Bemiddeling en advies" />

        <Typography
          data-aos="fade-right"
          variant="h5"
          style={{ marginBottom: 30, marginTop: 20 }}
        >
          Bemiddeling en advies
        </Typography>
        <Grid container spacing={3}>
          <Grid
            container
            item={true}
            spacing={3}
            sm={6}
            justifyContent="center"
            xs={12}
            display="flex"
            flexDirection="column"
          >
            <div
              data-aos="fade-right"
              style={{ width: "90%", margin: "20px 5% 0% 5%" }}
            >
              <Image
                alt="Bemiddeling en advies - algemeen"
                filename="bemiddeling-en-advies-main-min.jpg"
              />
            </div>
          </Grid>

          <Grid item={true} sm={6} xs={12}>
            <Typography
              data-aos="fade-right"
              variant="subtitle1"
              style={{ marginBottom: 30 }}
            >
              Gaat u scheiden of overweegt u om te scheiden? Zijn er conflicten
              in de familie of binnen het gezin? <br />
              <br />
              <strong>
                Ik help u om tot een oplossing te komen die in het belang is van
                alle partijen, zowel voor de partners als voor de kinderen.
                Daarbij staat overleg centraal,
              </strong>{" "}
              met begrip voor de standpunten van alle partijen.
              <br />
              <br /> Daarnaast geef ik u (juridisch) advies over praktische en
              emotionele vragen aangaande uw gezinssituatie: wettelijke kaders
              (inzake de beëindiging van de relatie, samenlevingscontracten bij
              wettelijke samenwoning, inzake het vermogen,…), informatie over de
              emotionele en praktische gevolgen van een echtscheiding, duiding,…
            </Typography>
          </Grid>
        </Grid>
        <Typography
          data-aos="fade-left"
          data-aos-delay="300"
          variant="subtitle1"
          style={{ marginBottom: 30 }}
        >
          Ik help u met
          <ul>
            <li>
              Advies bij <strong>echtscheiding</strong> en{" "}
              <strong>familiale problemen</strong>
            </li>
            <li>
              De <strong>regeling van uw vermogen</strong> wanneer uw huwelijk
              of relatie stopgezet wordt: de afbakening van de eigen en de
              gemeenschappelijke vermogens van echtgenoten/partners, de
              onverdeeldheden en de vereffening-verdeling ervan, erfenissen,
              schenkingen…
            </li>
            <li>
              Problemen aangaande de{" "}
              <strong>verblijfs- en onderhoudsregeling</strong> voor kinderen
            </li>
            <li>
              Een <strong>overeenkomst tot echtscheiding</strong> door
              onderlinge toestemming op te stellen
            </li>
            <li>
              Een samenlevingscontract op te stellen
            </li>
            <li>
              Een{" "}
              <strong>
                overeenkomst bij de beëindiging van de feitelijke of wettelijke
                samenwoning
              </strong> op te stellen
            </li>
          </ul>
        </Typography>
      </Layout>
    </ThemeProvider>
  </React.Fragment>
);

export default BemiddelingEnAdviesPage;
